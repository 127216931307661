import { Country } from "./Country";
import { Language } from "./Language";
import { NotificationType } from "./Notification";
import { User } from "./User";

export interface UserProfile {
  [key: string]: any;
  id: number;
  userId: string;
  user: User;
  languageId: number;
  language: Language;
  profileImage: string;
  birthYear?: number;
  position: Position;
  sex?: Sex;
  maritalStatus?: MaritalStatus;
  referralSource?: ReferralSource;
  countryId?: number;
  country?: Country;
  canton?: string;
  salaryRange?: SalaryRange;
  covidYear?: number;
  covidSeason?: Season;
  covidTest?: boolean;
  covidTestYear?: number;
  covidTestSeason?: Season;
  covidPreWorkload?: number;
  covidPostWorkload?: number;
  postNotificationType: NotificationType;
  topicNotificationType: NotificationType;
  commentNotificationType: NotificationType;
  profileImageBase64: string;
  profileImageMimeType: string;
}

export const defaultUserProfile: UserProfile = {
  id: 0,
  userId: "",
  user: null,
  position: 0,
  languageId: 1,
  language: null,
  profileImage: "",
  postNotificationType: 0,
  topicNotificationType: 0,
  commentNotificationType: 0,
  profileImageBase64: "",
  profileImageMimeType: ""
};

export enum Position {
  Affected = 1,
  RelativesAffected = 2,
  Doctor = 3,
  Scientist = 4,
  Other = 5
}

export enum Sex {
  Male = 1,
  Female = 2,
  Diverse = 3,
  Indefinite = 4
}

export enum MaritalStatus {
  Single = 1,
  Married = 2,
  Divorced = 3,
  Widowed = 4,
  UnmarriedPartnership = 5,
  DissolvedPartnership = 6
}

export enum ReferralSource {
  InternetSearch = 1,
  RadioTv = 2,
  MediaRelease = 3,
  OtherWebsites = 4,
  ThroughDoctor = 5,
  Friends = 6,
  Other = 7
}

export enum SalaryRange {
  Range1 = 1,
  Range2 = 2,
  Range3 = 3,
  Range4 = 4,
  Range5 = 5
}

export enum Season {
  January = 1,
  February = 2,
  March = 3,
  April = 4,
  May = 5,
  June = 6,
  July = 7,
  August = 8,
  September = 9,
  October = 10,
  November = 11,
  December = 12
}