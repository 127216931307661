import * as React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Routes from "../routes";
import { bindActionCreators } from "redux";
import { useThunkDispatch } from "../utils/thunk-dispatch";
import { getCurrentUser } from "../features/user-feature";
import { isIE } from "react-device-detect";
import BrowserNotSupported from "../components/browser-not-supported/BrowserNotSupported";
import { useSelector } from "react-redux";
import { AppState } from "../interfaces/general/App-State";
import { User } from "../interfaces/User";
import { useTranslation } from "react-i18next";
import * as ClientLanguageActions from "../client/language";
import * as ClientNotificationActions from "../client/notification";
import { Language } from "../interfaces/Language";
import { Notification } from "../interfaces/Notification";
import { useLocation } from "react-router-dom";

const App: React.FC = () => {
  const { t, i18n } = useTranslation();
  const asyncDispatch = useThunkDispatch();
  const actions = bindActionCreators({ getCurrentUser }, asyncDispatch);
  const user = useSelector<AppState, User>((state) => state.user);
  const [languages, setLanguages] = React.useState<Language[]>([]);
  const [notifications, setNotifications] = React.useState<Notification[]>([]);
  const urlParamLang = new URLSearchParams(useLocation().search).get("lang");

  React.useEffect(() => {
    actions.getCurrentUser();    
    getLanguages();
  }, []);

  React.useEffect(() => {
    if ((!user || user.id == "") && urlParamLang){
      const language = languages.find((language) => {
        return language.shortName.toLocaleLowerCase() == urlParamLang.toLocaleLowerCase();
      });
      if (language){
        localStorage.setItem("languageId", language.id.toString());
      }
    }
  }, [languages]);

  const getLanguages = () => {
    ClientLanguageActions.getAll().then(
      (response) => {
        setLanguages(response.data);
      },
      (error) => {
        setLanguages([]);
      }
    );
  };

  const getNotifications = () => {
    ClientNotificationActions.getAll().then(
      (response) => {
        setNotifications(response.data);
      },
      (error) => {
        setNotifications([]);
      }
    );
  };

  React.useEffect(() => {
    if (user && user.userProfile && user.userProfile.language && user.userProfile.language.shortName) {
      i18n.changeLanguage(user.userProfile.language.shortName);
    } else if (languages && languages.length && localStorage.getItem("languageId")) {
      i18n.changeLanguage(languages.find(x => x.id == parseInt(localStorage.getItem("languageId")))?.shortName.toLowerCase());
    }
    
    getNotifications();
  }, [user, languages]);

  return (
    <>
      <Header languages={languages} notifications={notifications} removeNotification={(notificationId: number) => setNotifications([...notifications.filter(x => x.id !== notificationId)])} />
      <main>
        {isIE ? <BrowserNotSupported /> : <Routes />}
      </main>
      <Footer />
    </>
  );
};

export default App;
