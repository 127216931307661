import axios from "axios";
import { ChangeEmailConfirmDto } from "../interfaces/dto/ChangeEmailConfirmDto";
import { InviteUserDto } from "../interfaces/dto/InviteUserDto";
import { UserDto } from "../interfaces/dto/UserDto";
import { NotificationType } from "../interfaces/Notification";
import { User } from "../interfaces/User";

export const getCurrent = () => {
    return axios.get("/users/getCurrent");
}

export const getAll = () => {
    return axios.get("/users/getAll");
}

export const getProfileImage = (userId: string) => {
    return axios.get("/users/getProfileImage", { params: { userId } })
}

export const getById = (userId: string) => {
    return axios.get("/users/getById", { params: { userId } })
}

export const checkUserName = (userName: string) => {
    return axios.get("/users/checkUserName", { params: { userName } })
}

export const checkEmail = (email: string) => {
    return axios.get("/users/checkEmail", { params: { email } })
}

export const add = (user: User) => {
    return axios.post("/users/add", user);
}

export const update = (user: User) => {
    return axios.post("/users/update", user);
}

export const updateCurrentUser = (userDto: UserDto) =>  {
    return axios.put("/users/updateCurrentUser", userDto);
}

export const changeEmailConfirm = (changeEmailConfirmDto: ChangeEmailConfirmDto) => {
    return axios.post("/users/changeEmailConfirm", changeEmailConfirmDto);
}

export const updateLanguage = (languageId : number) => {
    return axios.put(`/users/updateLanguage?languageId=${languageId}`);
}

export const updateNotificationTypes = (postNotificationType: NotificationType, topicNotificationType: NotificationType, commentNotificationType: NotificationType) => {
    return axios.put(`/users/updateNotificationTypes?postNotificationType=${postNotificationType}&topicNotificationType=${topicNotificationType}&commentNotificationType=${commentNotificationType}`);
}

export const deleteUser = (userId : string) => {
    return axios.put(`/users/delete?id=${userId}`);
}

export const inviteUser = (inviteUserDto : InviteUserDto) => {
    return axios.post(`/users/invite`,inviteUserDto);
}