import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { AppState } from "../../interfaces/general/App-State";
import { User } from "../../interfaces/User";

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const user = useSelector<AppState, User>((state) => state.user);

  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-auto">
            <p className="text medium title">{t("footer.community")}</p>
          </div>
          <div className="col-12 col-lg-auto ml-lg-auto">
            <div className="footer-links row">
              {user && user.id && user.active && !user.banned ? (
                <>
                  <div className="col-6 col-lg-auto">
                    <NavLink to="/dashboard">{t("footer.dashboard")}</NavLink>
                  </div>
                </>
              ) : (
                ""
              )}
              <div className="col-6 col-lg-auto">
                <NavLink to="/community-rules" target="_blank">
                  {t("footer.rules")}
                </NavLink>
              </div>
              <div className="col-6 col-lg-auto">
                <NavLink to="/faq">{t("footer.faq")}</NavLink>
              </div>
              <div className="col-6 col-lg-auto">
                <NavLink to="/privacy-policy">
                  {t("footer.privacyPolicy")}
                </NavLink>
              </div>
              <div className="col-6 col-lg-auto">
                <NavLink to="/imprint">{t("imprint.title")}</NavLink>
              </div>
              <div className="col-6 col-lg-auto">
                <a href="https://www.altea-network.com/" target="_blank">
                  {t("footer.alteaWebsite")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
