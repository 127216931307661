import { User } from "../interfaces/User";

export const parseJwt = () => {
    if (!localStorage.getItem("jwtToken")) { return {}; }
    const base64Url = localStorage.getItem("jwtToken").split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
}

export const isAdministrator = (currentUser : User) => {
    return currentUser.roleName && (currentUser.roleName?.toLowerCase() === "administrator");
}

export const isAdminOrModerator = (currentUser : User) => {
    return currentUser && currentUser.roleName && (currentUser.roleName?.toLowerCase() === "administrator" || currentUser.roleName?.toLowerCase() === "moderator");
}

export const isAdminOrModeratorFromUserRole = (currentUser : User) => {
    return currentUser && currentUser.userRoles && currentUser.userRoles.length > 0 && (currentUser.userRoles[0].role?.name.toLowerCase() === "administrator" || currentUser.userRoles[0].role?.name.toLowerCase() === "moderator");
}

export const isAdminOrModeratorFromRole = (role : string) => {
    return role && (role.toLowerCase() === "administrator" || role.toLowerCase() === "moderator");
}