import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { Notification } from "../../interfaces/Notification";
import * as ClientNotificationActions from "../../client/notification";

interface Props {
  isOpen: boolean;
  notifications: Notification[];
  removeNotification: (notificationId: number) => void;
}

const NotificationList: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const { t } = useTranslation();

  const handleLinkClick =
    (notificationId: number, href: string) =>
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      e.preventDefault();
      ClientNotificationActions.markAsRead(notificationId).then(() => {
        history.push(href);
        props.removeNotification(notificationId);
      });
    };

  const getLinkFromNotification = (notification: Notification) => {
    const { topicId, postId, commentId, comment } = notification;
    if (topicId) {
      return `/forum/topic/${topicId}`;
    } else if (postId) {
      return `/forum/post/${postId}`;
    } else if (commentId && comment) {
      return `/forum/post/${comment.postId}/${commentId}`;
    }
  };

  const formatNotificationText = (notification: Notification) => {
    const { id, notificationText, topicId, postId, commentId } = notification;
    const textArray = notificationText.split('"');
    if (textArray.length > 3 || (topicId === 0 && postId === 0 && commentId === 0)) return notificationText; // if we have more than 3 strings the text is not in the expected format.
    const href = getLinkFromNotification(notification);
    const link = (
      <Link onClick={handleLinkClick(id, href)} to={href} className="link fs-16">
        {textArray[1]}
      </Link>
    );
    return (
      <>
        {textArray[0]} {link} {textArray[2]}
      </>
    );
  };

  const formatCreateDate = (createDate: Date) => {
    const diff = moment(moment()).diff(createDate, "hours", true);
    if (diff < 1) {
      const minutes = moment(moment()).diff(createDate, "minutes");
      return t("forum.minutesAgo", { count: minutes });
    } else if (diff < 24) {
      const hours = moment(moment()).diff(createDate, "hours");
      return t("forum.hoursAgo", { count: hours });
    } else {
      return moment(createDate).format("DD.MM.YYYY");
    }
  };

  return (
    <ul className={`dropdown notifications ${props.isOpen ? "open" : ""}`}>
      <div className="format"></div>
      <li className="medium">{t("navigation.notes")}</li>
      {props.notifications && props.notifications.length ? (
        props.notifications.map((notification) => (
          <li
            key={notification.id}
            className={(notification.commentId && "highlighted") || null}
            onClick={handleLinkClick(notification.id, getLinkFromNotification(notification))}
          >
            <p className="text fs-16">{formatNotificationText(notification)}</p>
            <span className="date">{formatCreateDate(notification.dateCreated)}</span>
          </li>
        ))
      ) : (
        <li>
          <p className="text fs-16">{t("navigation.noNotes")}</p>
        </li>
      )}
    </ul>
  );
};

export default NotificationList;
