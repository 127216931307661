import axios from "axios";
import { GoogleLoginDto } from "../interfaces/dto/GoogleLoginDto";
import { LoginDto } from "../interfaces/dto/LoginDto";
import { PasswordResetDto } from "../interfaces/dto/PasswordResetDto";
import { RegisterConfirmDto } from "../interfaces/dto/RegisterConfirmDto";
import { RegisterDto } from "../interfaces/dto/RegisterDto";

export const getPublicRecaptchaKey = () => {
    return axios.get("/authentication/getPublicRecaptchaKey");
};

export const login = (loginDto: LoginDto) => {
    return axios.post("/authentication/login", loginDto);
};

export const googleLogin = (googleLoginDto: GoogleLoginDto) => {
    return axios.post("/authentication/googleLogin", googleLoginDto);
};

export const register = (registerDto: RegisterDto) => {
    return axios.post("/authentication/register", registerDto);
}

export const registerGoogleLogin = (googleLoginDto: GoogleLoginDto) => {
    return axios.post("/authentication/registerGoogleLogin", googleLoginDto);
}

export const registerConfirm = (registerConfirmDto: RegisterConfirmDto) => {
    return axios.post("/authentication/registerConfirm", registerConfirmDto);
}

export const recoverPassword = (email : string) => {
    return axios.post(`/authentication/recoverPassword?email=${email}`);
}

export const reset = (passwordResetDto: PasswordResetDto) => {
    return axios.post(`/authentication/resetpassword`, passwordResetDto);
};