import * as React from "react";
import { Link, Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NotAuthorized: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div style={{ position: "fixed", width: "100%", height: "100%", background: "white", top: 0 }}>
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <h1 style={{ fontWeight: "bold" }}>{t("notAuthorized.title")}</h1>
        <h3>{t("notAuthorized.text")}</h3>
        <h3>
          {t("notAuthorized.link.part1")}
          <Link className="link space-left" to="/">
            {t("notAuthorized.link.part2")}
          </Link>
        </h3>
      </div>
    </div>
  );
};

export default NotAuthorized;
