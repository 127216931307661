import { User, defaultUser } from "../interfaces/User";
import { ActionType, createAction } from "typesafe-actions";
import { Dispatch } from "redux";
import * as ClientUserActions from "../client/user";
import * as ClientAuthenticationActions from "../client/authentication";
import { LoginDto } from "../interfaces/dto/LoginDto";
import { GoogleLoginDto } from "../interfaces/dto/GoogleLoginDto";
import { UserDto } from "../interfaces/dto/UserDto";
import { AxiosResponse } from "axios";

const loginRequest = createAction("user/LOGIN_REQUEST")();
const loginFailure = createAction("user/LOGIN_FAILURE")();
const loginSuccess = createAction("user/LOGIN_SUCCESS")<string>();

const googleLoginRequest = createAction("user/GOOGLE_LOGIN_REQUEST")();
const googleLoginFailure = createAction("user/GOOGLE_LOGIN_FAILURE")();
const googleLoginSuccess = createAction("user/GOOGLE_LOGIN_SUCCESS")<string>();

const logoutSuccess = createAction("user/LOGOUT_SUCCESS")();

const getCurrentUserRequest = createAction("user/GET_CURRENT_REQUEST")();
const getCurrentUserFailure = createAction("user/GET_CURRENT_FAILURE")();
const getCurrentUserSuccess = createAction("user/GET_CURRENT_SUCCESS")<User>();

const updateCurrentUserRequest = createAction("user/UPDATE_CURRENT_REQUEST")();
const updateCurrentUserFailure = createAction("user/UPDATE_CURRENT_FAILURE")();
const updateCurrentUserSuccess = createAction("user/UPDATE_CURRENT_SUCCESS")<UserDto>();

const registerGoogleLoginRequest = createAction("user/REGISTER_GOOGLE_LOGIN_REQUEST")();
const registerGoogleLoginFailure = createAction("user/REGISTER_GOOGLE_LOGIN_FAILURE")();
const registerGoogleLoginSuccess = createAction("user/REGISTER_GOOGLE_LOGIN_SUCCESS")<User>();

export const login = (login: LoginDto) => {
  return async (dispatch: Dispatch) => {
    dispatch(loginRequest());
    return ClientAuthenticationActions.login(login)
      .then((response: any) => {
        dispatch(loginSuccess(response.data));
        localStorage.setItem("jwtToken", response.data);
        return dispatch<any>(getCurrentUser());
      })
      .catch((error) => {
        dispatch(loginFailure());
        return Promise.reject(error);
      });
  };
};

export const googleLogin = (googleLoginDto: GoogleLoginDto) => {
  return async (dispatch: Dispatch) => {
    dispatch(googleLoginRequest());
    return ClientAuthenticationActions.googleLogin(googleLoginDto)
      .then((response: any) => {
        dispatch(googleLoginSuccess(response.data));
        localStorage.setItem("jwtToken", response.data);
        return dispatch<any>(getCurrentUser());
      })
      .catch((error) => {
        dispatch(googleLoginFailure());
        return Promise.reject(error);
      });
  };
};

export const logout = () => {
  return (dispatch: Dispatch) => {
    localStorage.setItem("jwtToken", "");
    dispatch(logoutSuccess());
  };
};

export const getCurrentUser = () => {
  return (dispatch: Dispatch) => {
    dispatch(getCurrentUserRequest());
    if (localStorage.getItem("jwtToken")) {
      ClientUserActions.getCurrent()
        .then((response: any) => {
          dispatch(getCurrentUserSuccess(response.data));
        })
        .catch(() => {
          dispatch(getCurrentUserFailure());
        });
    } else {
      dispatch(getCurrentUserFailure());
    }
  };
};

export const updateCurrentUser = (updatedUser: UserDto) => {
  return async (dispatch: Dispatch) => {
    dispatch(updateCurrentUserRequest());
    if (localStorage.getItem("jwtToken")) {
      return ClientUserActions.updateCurrentUser(updatedUser).then(
        (response: AxiosResponse) => {
          dispatch(updateCurrentUserSuccess(response.data));
          return response;
        },
        (error) => {
          dispatch(updateCurrentUserFailure());
          return Promise.reject(error);
        }
      );
    } else {
      return dispatch(updateCurrentUserFailure());
    }
  };
};

export const registerGoogleLogin = (googleLoginDto: GoogleLoginDto) => {
  return (dispatch: Dispatch) => {
    dispatch(registerGoogleLoginRequest());
    return ClientAuthenticationActions.registerGoogleLogin(googleLoginDto)
      .then((response: any) => {
        dispatch(registerGoogleLoginSuccess(response.data));
        return response;
      })
      .catch((error: any) => {
        dispatch(registerGoogleLoginFailure());
      });
  };
};

export type UserActions = ActionType<
  | typeof loginRequest
  | typeof loginFailure
  | typeof loginSuccess
  | typeof googleLoginRequest
  | typeof googleLoginFailure
  | typeof googleLoginSuccess
  | typeof logoutSuccess
  | typeof getCurrentUserRequest
  | typeof getCurrentUserFailure
  | typeof getCurrentUserSuccess
  | typeof updateCurrentUserRequest
  | typeof updateCurrentUserFailure
  | typeof updateCurrentUserSuccess
  | typeof registerGoogleLoginRequest
  | typeof registerGoogleLoginFailure
  | typeof registerGoogleLoginSuccess
>;

export const userReducer = (state: User = defaultUser, action: UserActions) => {
  switch (action.type) {
    case "user/GET_CURRENT_SUCCESS":
      return action.payload;
    case "user/REGISTER_GOOGLE_LOGIN_SUCCESS":
      return action.payload;
    case "user/LOGOUT_SUCCESS":
      return defaultUser;
    case "user/UPDATE_CURRENT_SUCCESS":
      return action.payload;
    default:
      return state;
  }
};
