import React from "react";
import { AppState } from "../../interfaces/general/App-State";
import { User } from "../../interfaces/User";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Language } from "../../interfaces/Language";
import { useOuterClick } from "../../hooks/useOuterClick";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import * as ClientUserProfilesActions from "../../client/userProfile";
import NotificationList from "./NotificationList";
import { Notification } from "../../interfaces/Notification";
interface Props {
  languages: Language[];
  notifications: Notification[];
  removeNotification: (notificationId: number) => void;
}

const Header: React.FC<Props> = (props: Props) => {
  const { t, i18n } = useTranslation();
  const user = useSelector<AppState, User>((state) => state.user);
  const history = useHistory();
  const [isMobileNavOpen, setIsMobileNavOpen] = React.useState<boolean>(false);
  const [showMobileLanguageMenu, setShowMobileLanguageMenu] = React.useState<boolean>(false);
  const [languageDropdownOpen, setLanguageDropdownOpen] = React.useState<boolean>(false);
  const [profileDropdownOpen, setProfileDropdownOpen] = React.useState<boolean>(false);
  const [notificationDropdownOpen, setNotificationDropdownOpen] = React.useState<boolean>(false);
  const [profileImage, setProfileImage] = React.useState<string>("");

  React.useEffect(() => {
    if (user && user.id && user.active && !user.banned) {
      getProfileImage();
    }
  }, [user.userProfile]);

  const innerRefLanguageDropdown = useOuterClick(() => {
    if (languageDropdownOpen) toggleLanguageDropdown();
  });
  const innerRefProfileDropdown = useOuterClick(() => {
    if (profileDropdownOpen) toggleProfileDropdown();
  });
  const innerRefNotificationDropdown = useOuterClick(() => {
    if (notificationDropdownOpen) toggleNotificationDropdown();
  });
  const currentUrl = useLocation().pathname;

  const toggleLanguageDropdown = () => {
    setLanguageDropdownOpen(!languageDropdownOpen);
  };

  const toggleProfileDropdown = () => {
    if (window.innerWidth > 991) {
      setProfileDropdownOpen(!profileDropdownOpen);
    } else {
      history.push("/profile");
      closeMobileNav();
    }
  };

  const toggleNotificationDropdown = () => {
    setNotificationDropdownOpen(!notificationDropdownOpen);
  };

  const toggleLanguage = (languageId: number) => {
    i18n.changeLanguage(props.languages.find((x) => x.id == languageId)?.shortName.toLowerCase());
    toggleLanguageDropdown();
    localStorage.setItem("languageId", languageId.toString());
  };

  const closeMobileNav = () => {
    setIsMobileNavOpen(false);
    setShowMobileLanguageMenu(false);
  };

  const b64toBlob = (dataURI: any, type: any) => {
    var byteString = atob(dataURI.split(",")[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: type });
  };

  const getProfileImage = () => {
    if (user.userProfile.profileImage) {
      var mimeType = user.userProfile.profileImage.split(".")[1];
      ClientUserProfilesActions.getProfileImage().then(
        (response) => {
          const baseData = "data:" + mimeType + ";base64," + response.data.result;
          var url = URL.createObjectURL(b64toBlob(baseData, mimeType));
          setProfileImage(url);
        },
        (error) => { }
      );
    }
  };

  const renderNavItems = () => {
    if (user && user.id && user.active && !user.banned) {
      return (
        <>
          <li className="nav-item">
            <NavLink className="nav-link fs-16" to="/dashboard" onClick={closeMobileNav}>
              {t("navigation.dashboard")}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link fs-16" to="/forum" onClick={closeMobileNav}>
              {t("navigation.forum")}
            </NavLink>
          </li>
          <li className={`nav-item onlyMobile`}>
            <NavLink className="nav-link fs-16" to="/settings" onClick={closeMobileNav}>
              {t("navigation.settings")}
            </NavLink>
          </li>
          <li className={`nav-item onlyMobile spacer`}></li>
          {/* <li className="nav-item">
            <NavLink className="nav-link fs-16" to="/help" onClick={closeMobileNav}>
              {t("navigation.help")}
            </NavLink>
          </li> */}
          <li className={`nav-item onlyMobile`}>
            <NavLink className="nav-link fs-16" to="/faq" onClick={closeMobileNav}>
              {t("footer.faq")}
            </NavLink>
          </li>
          <li className={`nav-item onlyMobile`}>
            <NavLink className="nav-link fs-16" to="/community-rules" onClick={closeMobileNav}>
              {t("footer.rules")}
            </NavLink>
          </li>
          <li className={`nav-item onlyMobile`}>
            <NavLink className="nav-link fs-16" to="/privacy-policy" onClick={closeMobileNav}>
              {t("footer.privacyPolicy")}
            </NavLink>
          </li>
          <li className="nav-item onlyDesktop" ref={innerRefNotificationDropdown} onClick={toggleNotificationDropdown}>
            <img src="/public/img/icons/bell.svg" className="bell" alt="bell icon" id="notifications" />
            {(props.notifications && props.notifications.length && (
              <div className="notification-counter">{props.notifications.length}</div>
            )) ||
              null}
            <NotificationList
              isOpen={notificationDropdownOpen}
              notifications={props.notifications}
              removeNotification={(notificationId: number) => props.removeNotification(notificationId)}
            />
          </li>
          <li className="nav-item" ref={innerRefProfileDropdown}>
            <a className="nav-link fs-16 space" id="profile" onClick={toggleProfileDropdown}>
              <span className="userName">{user.userName}</span>
              <img
                src={profileImage && profileImage != "" ? profileImage : "/public/img/icons/icon_default_avatar.svg"}
                className="profile-pic"
                alt="profile picture"
              />
            </a>
            <ul className={`dropdown profile ${profileDropdownOpen ? "open" : ""}`}>
              <div className="format"></div>
              <li>
                <NavLink to="/profile">{t("navigation.myProfile")}</NavLink>
              </li>
              <li>
                <NavLink to="/settings">{t("navigation.mySettings")}</NavLink>
              </li>
              <li>
                <NavLink to="/logout">{t("navigation.logout")}</NavLink>
              </li>
            </ul>
          </li>
        </>
      );
    } else {
      return (
        <>
          {currentUrl.includes("register") ? (
            <li className="nav-item onlyDesktop">
              {t("navigation.alreadyMember")}
              <NavLink to="/login" className="nav-link link fs-16 space-left">
                {t("navigation.login")}
              </NavLink>
            </li>
          ) : currentUrl.includes("login") || currentUrl.includes("password") ? (
            <li className="nav-item onlyDesktop">
              {t("navigation.becomeMember")}
              <NavLink to="/register" className="nav-link link fs-16 space-left">
                {t("navigation.registerNow")}
              </NavLink>
            </li>
          ) : (
            <li className="nav-item onlyDesktop">
              <NavLink to="/login" className="login-button">
                {t("navigation.signIn")}
              </NavLink>
              <NavLink to="/register" className="register-button">
                {t("navigation.register")}
              </NavLink>
            </li>
          )}
          <li className="nav-item onlyDesktop" ref={innerRefLanguageDropdown}>
            {t("navigation.language")}
            <a className="link fs-16" id="choose-language" onClick={toggleLanguageDropdown}>
              {i18n.language.toUpperCase()}
              <img
                src="/public/img/icons/arrow-down-language.svg"
                className={`dropdown-icon ${languageDropdownOpen ? "rotate" : ""}`}
                alt="dropdown icon"
              />
            </a>
            <ul className={`dropdown language ${languageDropdownOpen ? "open" : ""}`}>
              <div className="format"></div>
              {props.languages &&
                props.languages.map((l) => (
                  <li key={l.id} className={i18n.language.toLowerCase() === l.shortName.toLowerCase() ? "active" : ""}>
                    <a onClick={() => toggleLanguage(l.id)}>{l.shortName.toUpperCase()}</a>
                  </li>
                ))}
            </ul>
          </li>
          <li className={`nav-item languages onlyMobile ${showMobileLanguageMenu ? "open" : ""}`}>
            <span onClick={() => setShowMobileLanguageMenu(true)}>{t("navigation.language")}</span>
            <ul className="mobileLanguageMenu">
              {props.languages &&
                props.languages.map((l) => (
                  <li
                    key={l.id}
                    className={`nav-item ${i18n.language.toLowerCase() === l.shortName.toLowerCase() ? "active" : ""}`}
                  >
                    <a
                      className="nav-link"
                      onClick={() => {
                        toggleLanguage(l.id);
                        closeMobileNav();
                      }}
                    >
                      {l.name && l.name.toUpperCase()}
                    </a>
                  </li>
                ))}
              <li className="nav-item spacer"></li>
              <li className="nav-item back" onClick={() => setShowMobileLanguageMenu(false)}>
                {t("back")}
              </li>
            </ul>
          </li>
        </>
      );
    }
  };

  const renderMobileMetaNav = () => {
    if (user && user.id && user.active && !user.banned) {
      return (
        <li className={`nav-item`}>
          <NavLink className="nav-link fs-16" to="/logout" onClick={closeMobileNav}>
            {t("navigation.logout")}
          </NavLink>
        </li>
      );
    } else {
      if (currentUrl.includes("register")) {
        return (
          <li className={`nav-item`}>
            <NavLink className="nav-link fs-16" to="/login" onClick={closeMobileNav}>
              {t("navigation.login")}
            </NavLink>
          </li>
        );
      } else if (currentUrl.includes("login") || currentUrl.includes("password")) {
        return (
          <li className={`nav-item`}>
            <NavLink className="nav-link fs-16" to="/register" onClick={closeMobileNav}>
              {t("navigation.register")}
            </NavLink>
          </li>
        );
      } else {
        return (
          <>
            <li className={`nav-item`}>
              <NavLink className="nav-link fs-16" to="/login" onClick={closeMobileNav}>
                {t("navigation.signIn")}
              </NavLink>
            </li>
            <li className={`nav-item`}>
              <NavLink className="nav-link fs-16" to="/register" onClick={closeMobileNav}>
                {t("navigation.register")}
              </NavLink>
            </li>
          </>
        );
      }
    }
  };

  return (
    <header>
      <nav className="container navbar navbar-expand-lg">
        <div className="navbar-icons d-flex h-100">
          <NavLink to="/" className="navbar-brand h-100">
            <img className="logo" src="/public/img/logo.svg" alt="logo" />
          </NavLink>
          <div className="ml-auto d-flex align-items-center h-100">
            <div className="nav-item onlyMobile pr-4" ref={innerRefNotificationDropdown} onClick={toggleNotificationDropdown}>
              <img src="/public/img/icons/bell.svg" className="bell" alt="bell icon" id="notifications" />
              {(props.notifications && props.notifications.length && (
                <div className="notification-counter">{props.notifications.length}</div>
              )) ||
                null}
              <NotificationList
                isOpen={notificationDropdownOpen}
                notifications={props.notifications}
                removeNotification={(notificationId: number) => props.removeNotification(notificationId)}
              />
            </div>
            <div
              className={`animated-icon ${isMobileNavOpen ? "open" : ""}`}
              onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>

        <div className={`navbar-collapse ${isMobileNavOpen ? "open" : ""}`}>
          <div className="navWrapper d-flex flex-column">
            <div className="container">
              <ul className="navbar-nav">{renderNavItems()}</ul>
            </div>
            <div className={`container metanav onlyMobile mt-auto ${showMobileLanguageMenu ? "hide" : ""}`}>
              <ul className="navbar-nav">{renderMobileMetaNav()}</ul>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
