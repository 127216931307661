import { CommentNotificationSubscription } from "./CommentNotificationSubscription";
import { CommentVote } from "./CommentVote";
import { Moderator } from "./Moderator";
import { Post } from "./Post";
import { PostNotificationSubscription } from "./postNotificationSubscription";
import { PostVote } from "./PostVote";
import { QueueReportComment } from "./QueueReportComment";
import { QueueReportPost } from "./QueueReportPost";
import { SurveyAnswerVote } from "./SurveyAnswerVote";
import { TopicNotificationSubscription } from "./topicNotificationSubscription";
import { defaultUserProfile, UserProfile } from "./UserProfile";
import { UserRole } from "./UserRole";

export interface User {
  [key: string]: any;
  id: string;
  userName: string;
  email: string;
  firstName: string;
  lastName: string;
  active: boolean;
  cautioned: boolean;
  banned: boolean;
  dateCreated: Date;
  userRoles: UserRole[];
  posts: Post[];
  postVotes: PostVote[];
  comments: Comment[];
  commentVotes: CommentVote[];
  moderators: Moderator[];
  userProfile: UserProfile;
  queueReportPosts: QueueReportPost[];
  queueReportComments: QueueReportComment[];
  postNotificationSubscriptions: PostNotificationSubscription[];
  topicNotificationSubscriptions: TopicNotificationSubscription[];
  commentNotificationSubscriptions: CommentNotificationSubscription[];
  notifications: Notification[];
  authoredNotifications: Notification[];
  surveyAnswerVotes: SurveyAnswerVote[];
}

export const defaultUser: User = {
  id: "",
  userName: "",
  email: "",
  firstName: "",
  lastName: "",
  active: true,
  cautioned: false,
  banned: false,
  dateCreated: new Date(),
  userRoles: [],
  posts: [],
  postVotes: [],
  comments: [],
  commentVotes: [],
  moderators: [],
  userProfile: defaultUserProfile,
  queueReportPosts: [],
  queueReportComments: [],
  postNotificationSubscriptions: [],
  topicNotificationSubscriptions: [],
  commentNotificationSubscriptions: [],
  notifications: [],
  authoredNotifications: [],
  surveyAnswerVotes: []
};
