import * as React from "react";
import { Switch, Route } from "react-router-dom";
import Loading from "./components/loading/Loading";
import * as loadable from 'react-loadable';
import ProtectedRoute from "./components/protected-route/ProtectedRoute";
import { useSelector } from "react-redux";
import { AppState } from "./interfaces/general/app-state";
import { User } from "./interfaces/user";
import { Request } from "./interfaces/general/request";
import { isAdministrator, isAdminOrModerator } from "./utils/authorization";

const AsyncHomeContainer = loadable ({loader: () => import(/* webpackChunkName: "Home" */ "./containers/home/Home"), loading: Loading});
const AsyncLoginContainer = loadable ({loader: () => import(/* webpackChunkName: "Login" */ "./containers/authentication/Login"), loading: Loading});
const AsyncLogoutContainer = loadable ({loader: () => import(/* webpackChunkName: "Logout" */ "./containers/authentication/Logout"), loading: Loading});
const AsyncForgotPasswordContainer = loadable ({loader: () => import(/* webpackChunkName: "ForgotPassword" */ "./containers/authentication/ForgotPassword"), loading: Loading});
const AsyncResetPasswordContainer = loadable ({loader: () => import(/* webpackChunkName: "ResetPassword" */ "./containers/authentication/ResetPassword"), loading: Loading});
const AsyncRegisterContainer = loadable ({loader: () => import(/* webpackChunkName: "Register" */ "./containers/authentication/Register"), loading: Loading});
const AsyncRegisterFormContainer = loadable ({loader: () => import(/* webpackChunkName: "RegisterForm" */ "./containers/authentication/RegisterForm"), loading: Loading});
const AsyncRegisterConfirmContainer = loadable ({loader: () => import(/* webpackChunkName: "RegisterConfirm" */ "./containers/authentication/RegisterConfirm"), loading: Loading});
const AsyncChangeEmailContainer = loadable ({loader: () => import(/* webpackChunkName: "ChangeEmail" */ "./containers/profile/ChangeEmail"), loading: Loading});
const AsyncCommunityRulesContainer = loadable ({loader: () => import(/* webpackChunkName: "CommunityRules" */ "./containers/legal-information/CommunityRules"), loading: Loading});
const AsyncPrivacyPolicyContainer = loadable ({loader: () => import(/* webpackChunkName: "PrivacyPolicy" */ "./containers/legal-information/PrivacyPolicy"), loading: Loading});
const AsyncImprintContainer = loadable ({loader: () => import(/* webpackChunkName: "Imprint" */ "./containers/legal-information/Imprint"), loading: Loading});
const AsyncHelpContainer = loadable ({loader: () => import(/* webpackChunkName: "Help" */ "./containers/help/Help"), loading: Loading});
const AsyncFaqContainer = loadable ({loader: () => import(/* webpackChunkName: "Faq" */ "./containers/faq/Faq"), loading: Loading});
const AsyncSettingsContainer = loadable ({loader: () => import(/* webpackChunkName: "Faq" */ "./containers/settings/Settings"), loading: Loading});
const AsyncForumOverviewContainer = loadable ({loader: () => import(/* webpackChunkName: "Forum Overview" */ "./containers/forum/Overview"), loading: Loading});
const AsyncTopicOverviewContainer = loadable ({loader: () => import(/* webpackChunkName: "Topic Overview" */ "./containers/forum/Topic"), loading: Loading});
const AsyncPostContainer = loadable ({loader: () => import(/* webpackChunkName: "Post" */ "./containers/forum/Post"), loading: Loading});
const AsyncPostAddContainer = loadable ({loader: () => import(/* webpackChunkName: "Post Add" */ "./containers/forum/PostAdd"), loading: Loading});
const AsyncPostEditContainer = loadable ({loader: () => import(/* webpackChunkName: "Post Edit" */ "./containers/forum/PostEdit"), loading: Loading});
const AsyncSurveyAddContainer = loadable ({loader: () => import(/* webpackChunkName: "Survey Add" */ "./containers/forum/SurveyAdd"), loading: Loading});
const AsyncSearchResultContainer = loadable({ loader: () => import(/* webpackChunkName: "Forum Search" */ "./containers/search/SearchResult"), loading: Loading });
const AsyncProfileContainer = loadable ({loader: () => import(/* webpackChunkName: "RegisterConfirm" */ "./containers/profile/Profile"), loading: Loading});
const AsyncDashboardContainer = loadable({ loader: () => import(/* webpackChunkName: "Dashboard" */ "./containers/dashboard/Dashboard"), loading: Loading });

const AsyncNotFoundContainer = loadable({ loader: () => import(/* webpackChunkName: "NotFound" */ "./containers/error-pages/NotFound"), loading: Loading });

const Routes: React.FC = () => {
    const requests = useSelector<AppState, Request[]>(state => state.requests);
    const [userRequestStatus, setUserRequestStatus] = React.useState("REQUEST");
    const user = useSelector<AppState, User>((state) => state.user);

    React.useEffect(() => {
        var userRequestString = requests.filter(x => x.type === "user/GET_CURRENT").length > 0 ? requests.filter(x => x.type === "user/GET_CURRENT")[0].status : "REQUEST";
        setUserRequestStatus(userRequestString);
    }, [requests]);

    return (
        <Switch>            
            <Route exact path="/" render={() => <AsyncHomeContainer /> } />
            <Route exact path="/login/:url?/:key?" render={() => <AsyncLoginContainer /> } />
            <Route exact path="/logout" render={() => <AsyncLogoutContainer /> } />
            <Route exact path="/register" render={() => <AsyncRegisterContainer /> } />
            <Route exact path="/register-form" render={() => <AsyncRegisterFormContainer /> } />
            <Route exact path="/register-confirm" render={() => <AsyncRegisterConfirmContainer /> } />
            <Route exact path="/change-email" render={() => <AsyncChangeEmailContainer /> } />
            <Route exact path="/forgot-password" render={() => <AsyncForgotPasswordContainer /> } />            
            <Route exact path="/reset-password" render={() => <AsyncResetPasswordContainer /> } />
            <Route exact path="/community-rules" render={() => <AsyncCommunityRulesContainer /> } />
            <Route exact path="/privacy-policy" render={() => <AsyncPrivacyPolicyContainer /> } />
            <Route exact path="/imprint" render={() => <AsyncImprintContainer /> } />
            <Route exact path="/faq" render={() => <AsyncFaqContainer /> } />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" && user.active && !user.banned} exact path="/profile" render={() => <AsyncProfileContainer /> } />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" && user.active && !user.banned} exact path="/settings" render={() => <AsyncSettingsContainer /> } />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" && user.active && !user.banned} exact path="/help" render={() => <AsyncHelpContainer /> } />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" && user.active && !user.banned} exact path="/forum" render={() => <AsyncForumOverviewContainer />} />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" && user.active && !user.banned} exact path="/forum/search/:key" render={() => <AsyncSearchResultContainer />} />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" && user.active && !user.banned} exact path="/dashboard" render={() => <AsyncDashboardContainer />} />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" && user.active && !user.banned} exact path="/forum/topic/:id" render={() => <AsyncTopicOverviewContainer /> } />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" && user.active && !user.banned} exact path="/forum/post/:id" render={() => <AsyncPostContainer /> } />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" && user.active && !user.banned} exact path="/forum/post/:id/:commentId" render={() => <AsyncPostContainer /> } />
            <ProtectedRoute isAdminOrModeratorOnly isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" && user.active && !user.banned && isAdminOrModerator(user)} exact path="/forum/post-add" render={() => <AsyncPostAddContainer /> } />
            <ProtectedRoute isAdminOrModeratorOnly isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" && user.active && !user.banned && isAdminOrModerator(user)} exact path="/forum/post-edit/:id" render={() => <AsyncPostEditContainer /> } />
            <ProtectedRoute isAdminOrModeratorOnly isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" && user.active && !user.banned && isAdminOrModerator(user)} exact path="/forum/survey-add" render={() => <AsyncSurveyAddContainer /> } />

            <Route component={AsyncNotFoundContainer} /> 
        </Switch>
    );
}

export default Routes;