import axios from "axios"

export const getAll = () => {
    return axios.get("/notifications/getAll");
}

export const getAllPostNotificationSubscriptions = () => {
    return axios.get("/notifications/getAllPostNotificationSubscriptions");
}

export const getAllTopicNotificationSubscriptions = () => {
    return axios.get("/notifications/getAllTopicNotificationSubscriptions");    
}

export const markAsRead = (notificationId: number) => {
    return axios.put(`/notifications/markAsRead?notificationId=${notificationId}`);
}

export const togglePostNotificationSubscription = (postId: number) => {
    return axios.post(`/notifications/togglePostNotificationSubscription?postId=${postId}`);
}

export const toggleTopicNotificationSubscription = (topicId: number) => {
    return axios.post(`/notifications/toggleTopicNotificationSubscription?topicId=${topicId}`);    
}

export const toggleCommentNotificationSubscription = (commentId: number) => {
    return axios.post(`/notifications/toggleCommentNotificationSubscription?commentId=${commentId}`);    
}